import { HttpClient } from "@angular/common/http";
import { EnvironmentService } from "./environment.service";
import { inject } from "@angular/core";

export abstract class BaseService {
    protected baseUrl: string;
    protected hostname: string;
  
    http = inject(HttpClient);
    environmentService = inject(EnvironmentService);

    constructor() {
      const environment = this.environmentService.resolve();
  
      switch(environment.envName) {
        case 'production':
        case 'development':
          this.baseUrl = '/api';
          break;
        case 'localhost':
          this.baseUrl = 'http://localhost:8008/api';
          break;
      }
  
      this.hostname = new URL(window.location.href).hostname.split('.')[0];
    }
}