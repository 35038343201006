import { createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import * as ThemeActions from './theme.actions';
export const featureKey = 'theme';

export interface State {
    dark: boolean;
}

export const initialState: State = {
    dark: false,
};

export const themeReducer = createReducer(
    initialState,
);

export const reducer = createReducer(
    initialState,

    on(ThemeActions.toggleTheme, (state, { dark }) => ({
        ...state,
        dark
    })),
);

export const themeState = createFeatureSelector<State>(featureKey);
export const theme = createSelector(themeState, (state: State) => state.dark);