/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export type SupportedEnvironments = 'production' | 'development' | 'localhost';

export interface IEnvironmentConfig {
	production: boolean;
	envName: string;
}

export class EnvironmentConfig implements IEnvironmentConfig {
	production: boolean;
	envName: SupportedEnvironments;

	constructor(args: IEnvironmentConfig) {
		const {
			production,
			envName,
		} = args;

		this.production = production;
		switch (envName) {
			case 'localhost':
			case 'production':
			case 'development': {
				this.envName = envName;
			} break;

			default: {
				throw new Error('Unsupported configuration!');
			}
		}
	}
}

export interface IEnvironmentService {
	resolve(): EnvironmentConfig;
}

@Injectable({
	providedIn: 'root'
})
export class EnvironmentService implements IEnvironmentService {
	resolve(): EnvironmentConfig {
		return new EnvironmentConfig(environment);
	}
}
/* eslint-enable max-classes-per-file */
